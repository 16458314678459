/* src/components/Title.css */

.title-container {
  text-align: center;
  margin-bottom:64px;
}

.gradient-title {
  font-size: 24px; /* Adjust size if needed */
  font-weight: 400; /* Increase the font weight to make it more bold */
  margin-bottom: 2px;
  background: linear-gradient(90deg, rgba(138,48,227,1) 0%, rgba(238,107,161,1) 50%, rgba(234,200,147,1) 100%);
  -webkit-background-clip: text;
  color: transparent;
  letter-spacing: 0.2em;
}

.title-container p {
  font-size: 16px;
  margin-top: 8px;
  color: #333;
}

  