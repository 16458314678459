.card {
    width: 336px;
    height: 336px;
    background: rgba(255, 255, 255, 0.1); /* Semi-transparent background */
    backdrop-filter: blur(10px); /* Blur effect for glassmorphism */
    border-radius: 16px;
    overflow: hidden;
    margin-top: 16px;
    transition: background 0.3s ease, transform 0.3s ease;
  }
  
  .card:hover {
    background: linear-gradient(135deg, #FFE9F4, #EAF3FF); /* Gradient on hover */
    transform: translateY(-5px); /* Slight lift effect on hover */
  }
  
  .card-image {
    width: calc(100% - 8px);
    height: 70%;
    object-fit: cover;
    margin: 4px;
    border-radius: 6px;
  }
  
  .card-text-section {
    display: flex;
    justify-content: space-between;
    padding: 12px;
    height: auto;
    box-sizing: border-box;
  }
  
  .text-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 10px;
  }
  
  .text-column h3 {
    margin-top: 2px;
    font-size: 16px;
    line-height: 1.2;
  }
  
  .text-column p {
    margin-bottom: 2px;
    font-size: 12px;
    color: #B4B4B4;
    text-align:left !important;
  }
  
  .icon-column {
    display: flex;
    align-items: center;
  }
  
  .icon-column img {
    width: 24px;
    height: 24px;
  }
  
 /* Ensure that the link does not display any default styling */
.card-link {
    text-decoration: none; /* Remove underline from links */
    color: inherit; /* Inherit color from parent */
    display: block; /* Make sure the link covers the entire card area */
    width: 100%; /* Ensure the link covers the full width of the card */
    height: 100%; /* Ensure the link covers the full height of the card */
  }
  
  .card {
    background: #fff;
    padding: 4px;
    border-radius: 10px;
    width: 100%;
    max-width: 336px;
    text-align: left;
    display: block; /* Ensure the card is block level */
  }
  
  .card:hover {
    background: linear-gradient(135deg, #FFE9F4, #EAF3FF); /* Gradient on hover */
    transform: translateY(-5px); /* Slight lift effect on hover */
  }

  .card-image-wrapper {
    position: relative;
  }
  
  .coming-soon-label {
    position: absolute;
    top: 0;
    left: 0;
    width: 40%;
    height: 20px; /* Adjust height as needed */
    background-color: rgba(255, 0, 0, 0.8); /* Semi-transparent red background */
    color: white; /* White text color */
    text-align: right; /* Center text */
    line-height: 20px; /* Vertically center text */
    font-size: 12px; /* Adjust font size as needed */
    z-index: 100; /* Ensure the label is above the image */
   

  }
  
