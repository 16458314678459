.coming-soon-label {
    position: absolute;
    top: 8%;
    left: 20%;
    transform: translate(-50%, -50%); /* Center the label */
    background: linear-gradient(45deg, #FDE1BC, #FFAEAE);
    color: #D65B25;
    padding: 2px 2px;
    border-radius: 20px;
    font-size: 12px;
    z-index: 10;
    font-weight: bold;
    white-space: nowrap; 
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
  }
  