.MyStory {
  max-width: 720px;
  margin: 0 auto;
  padding-top: 160px;
}

.title {
  margin-top: 80px !important;
  letter-spacing: 0.2em;
}

.timeline {
  margin-top: 40px;
}

.timeline-item {
  display: flex;
  margin-bottom: 40px;
}

.timeline-date {
  flex: 1;
  text-align: right;
  font-size: 16px;
  color: #A4A4A4;
  margin-right: 20px;
}

.timeline-card {
  flex: 3;
  display: flex;
  flex-direction: column;
}

.timeline-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.timeline-content {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.timeline-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.timeline-paragraph {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 10px;
}
