
/* src/index.css or src/App.css */

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: "San Francisco", sans-serif;
  background: url('./assests/header-gradient.png') no-repeat center top; /* Adjust path if needed */
  
}

@font-face {
  font-family: "San Francisco";
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfrancisco-medium-webfont.woff");
  font-weight: 400;
}

@font-face {
  font-family: "San Francisco";
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfrancisco-bold-webfont.woff");
  font-weight: 800;
}

