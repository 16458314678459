/* Add styles for the timeline here */
.timeline {
    display: flex;
    flex-direction: column;
  }
  
  .timeline-item {
    position: relative;
    margin-bottom: 40px;
  }
  
  .timeline-date {
    font-size: 16px;
    color: #A4A4A4;
  }
  
  .timeline-card {
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 8px;
    box-shadow: none; /* Ensure no shadow is applied */
    align-items: left;
    transition: background 0.3s ease, transform 0.3s ease; /* Smooth transition for hover effect */
    padding: 10 10 40 10;
  }
  
  .timeline-card:hover {
    background: linear-gradient(135deg, #FFE9F4, #EAF3FF); /* Gradient on hover */
    transform: translateY(-5px); /* Slight lift effect on hover */
  }
  
  .timeline-image {
    width: 100%;
    height: auto;
    border-radius: 8px 8px 0 0;
  }
  
  .timeline-content {
    padding: 20px 24px 32px 20px;
    box-shadow: none; /* Ensure no shadow is applied */
    background: transparent !important; /* Ensure no background color conflicts */
  }
  
  .timeline-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: -16px;
    text-align: left;
  }
  
  .timeline-paragraph {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: -16px; /* Adjusted margin for spacing */
    text-align: left !important; /* Align paragraph text to left */
  }
  