

.Recent-works {
  text-align: center;
  margin: 0 auto 0 auto;  /* Centering the section and adding top margin */
  max-width: 720px;
  padding-top: 180px;
  position: relative;

}



.Recent-works h2 {
  font-size: 24px;

  letter-spacing: 0.2em;
}

.Recent-works p {
  font-size: 14px;

  margin-top: 2px;
}

.wfilter-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 32px;
}

.wfilter-buttons button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wfilter-buttons button.active {
  font-weight: bold;
}

.wcards-container {
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;  /* Centering the container */
  padding: 0 16px;
}

