.About {
  text-align: center;
  padding-top: 160px;
  max-width: 720px;
  margin: 0 auto;
}

.title {
  margin-top: 80px !important;
  letter-spacing: 0.2em;
}

.About .about-content {
  margin-top: 0px;
}

.About p {
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
}

.profile-image {
  width: 100%;
  max-width: 720px; /* Ensure it scales with screen size */
  height: auto;
  border-radius: 2%; /* Rounded corners */
  margin-top: -12px;
  margin-bottom: 48px;
}

.tech-stack {
  color: #A4A4A4;
  text-align: left;
  margin-top: 40px;
}

.tech-stack h2 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 24px;
}

.tech-icons {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.tech-icon {
  width: 60px;
  height: 60px;
  margin: 10px;
}

.story-link {
  margin-top: 40px;
  text-align: left;
}

.story-link p {
  color: #A4A4A4;
  font-size: 16px;
  font-weight: 400;
}

.gradient-link {
  background: linear-gradient(90deg, #8A30E3, #EE6BA1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.download-section {
  margin-top: 20px; /* Space above the button */
}

.download-button {
  display: block;
  width: 100%; /* Full width */
  padding: 15px;
  text-align: center;
  font-size: 16px;
  color: transparent; /* Hide default text color */
  background: #fff; /* Default white background */
  border-radius: 12px;
  text-decoration: none;
  background: linear-gradient(45deg, #DC79FF, #256BFA); /* Gradient text color */
  -webkit-background-clip: text; /* For WebKit browsers */
  background-clip: text; /* For other browsers */
  transition: background 0.8s ease, color 0.8s ease, border-color 0.8s ease;
}

.download-button:hover {
  background: linear-gradient(45deg, #DC79FF, #256BFA); /* Gradient background on hover */
  color: #fff; /* White text color on hover */
  border-color: transparent; /* Remove border on hover */
}

@media (max-width: 768px) {
  .profile-image {
    max-width: 100%; /* Ensure the image scales with screen size */
  }
  .tech-icons {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
  .tech-icon {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 480px) {
  .About p, .tech-stack h2, .story-link p {
    font-size: 14px;
  }
  .profile-image {
    max-width: 100%;
  }
  .tech-icons {
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
  }
  .tech-icon {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 400px) {
  .profile-image {
    max-width: 100%;
  }
  .tech-icons {
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
  .tech-icon {
    width: 35px;
    height: 35px;
  }
  .About p, .tech-stack h2, .story-link p {
    font-size: 12px;
  }
}
