
@import url('https://fonts.googleapis.com/css2?family=Niconne&family=Tangerine:wght@400;700&display=swap');

@font-face {
    font-family: "San Francisco";
    src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfrancisco-light-webfont.woff");
    font-weight: 200; /* Use 300 for light font weight */
  }
  
  @font-face {
    font-family: "San Francisco";
    src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfrancisco-bold-webfont.woff");
    font-weight: 400;
  }
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 64px); /* Adjust if you have a fixed header */
    padding: 20px;
  }
  
  .hero-image {
    width: 100%;
    max-width: 250px; 
    height: auto; 
    margin-bottom: 36px; 
    border-radius: 500px;
    margin-top: 175px !important;
  }

  .skills-section {
    margin: 32px 0; /* Margin on top and bottom */
    text-align: center; /* Center-align the image */
  }
  
  .skills-image {
    width: 520px; /* Set width */
    height: auto; /* Height adjusts automatically to maintain aspect ratio */
  }
  

  /* Media Queries for Responsive Hero Image Size */
@media (max-width: 1200px) {
    .hero-image {
      width: 180px; /* Size for medium screens */
      height: 180px;
    }
  }
  
  @media (max-width: 992px) {
    .hero-image {
      width: 180px; /* Size for tablets */
      height: 180px;
    }
  }
  
  @media (max-width: 768px) {
    .hero-image {
      width: 160px; /* Size for small tablets and larger phones */
      height: 160px;
    }
  }
  
  @media (max-width: 576px) {
    .hero-image {
      width: 140px; /* Size for mobile phones */
      height: 140px;
    }
    .skills-image {
      width: 360px; /* Set width */
      height: auto; /* Height adjusts automatically to maintain aspect ratio */
    }
  }
  
  
  .container h1 {
    font-family: 'Tangerine', cursive;
    color: #333;
    text-align: center;
    font-size: 64px !important; 
    margin-top: 32px;
  }
  
  /* Media Queries for Responsive Font Sizes */
@media (max-width: 1200px) {
    .container h1 {
      font-size: 56px; /* Font size for medium screens */
    }
  }
  
  @media (max-width: 992px) {
    .container h1 {
      font-size: 48px; /* Font size for tablets */
    }
  }
  
  @media (max-width: 768px) {
    .container h1 {
      font-size: 40px; /* Font size for small tablets and larger phones */
    }
  }
  
  @media (max-width: 576px) {
    .container h1 {
      font-size: 40px !important; /* Font size for mobile phones */

    }
    .skills-image {
      width: 360px; /* Set width */
      height: auto; /* Height adjusts automatically to maintain aspect ratio */
    }
  }
  
  .container p {
    color: #817E7E;
    text-align: center;
    margin-top: 24px;
    font-weight: 300; /* Decrease font weight */
    font-size:16px;
  }
  
  /* Adjust paragraph font size responsively if needed */
  @media (max-width: 576px) {
    .container p {
      font-size: 16px; /* Font size for mobile phones */
    }
  }
  
  @media (min-width: 577px) and (max-width: 768px) {
    .container p {
      font-size: 16px; /* Font size for larger phones and small tablets */
    }
    
  }

  @media (min-width: 345px) and (max-width: 768px) {
    .container p {
      font-size: 12px; /* Font size for larger phones and small tablets */
    }
    
  }

  
  .sp {
    background: linear-gradient(45deg, #398CFD, #8854E5, #FF775C); /* Gradient colors */
    -webkit-background-clip: text; /* For WebKit browsers */
    background-clip: text; /* For other browsers */
    color: transparent; /* Hide the text color */
    font-weight: 00; 
  }

  .button-container {
    margin-top: 100px;
  }