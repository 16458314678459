/* General container styling */
.blog-container {
    width: 90%; /* Adjust width for responsiveness */
    max-width: 720px; /* Maintain a maximum width */
    margin: 0 auto;
    padding-top: 160px;
  }
  
  /* Title styling */
  .title {
    letter-spacing: 0.2em;
  }
  
  /* Blog cards layout */
  .blog-cards {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  /* Blog card styling */
  .blog-card {
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 8px;
    text-decoration: none; /* Remove underline from links */
    color: inherit; /* Inherit color from parent */
    transition: background 0.3s ease, transform 0.3s ease; /* Smooth transition for hover effect */
  }
  
  /* Hover effect for blog cards */
  .blog-card:hover {
    background: linear-gradient(135deg, #FFE9F4, #EAF3FF); /* Gradient on hover */
    transform: translateY(-5px); /* Slight lift effect on hover */
  }
  
  /* Blog image styling */
  .blog-image {
    width: 100%;
    height: auto;
    border-radius: 8px 8px 0 0;
  }
  
  /* Blog content styling */
  .blog-content {
    padding: 20px 24px 32px 20px;
  }
  
  /* Blog title styling */
  .blog-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
    text-align: left;
  }
  
  /* Blog date styling */
  .blog-date {
    font-size: 14px;
    color: #A4A4A4;
    margin-bottom: 8px;
  }
  
  /* Blog description styling */
  .blog-description {
    font-size: 16px;
    line-height: 1.5;
    text-align: left;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .blog-container {
      width: 95%; /* More padding on smaller screens */
      padding-top: 120px;
    }
    
    .blog-title {
      font-size: 16px; /* Smaller font size for titles on small screens */
    }
    
    .blog-date {
      font-size: 12px; /* Smaller font size for dates */
    }
    
    .blog-description {
      font-size: 14px; /* Smaller font size for descriptions */
    }
  }
  
  @media (max-width: 480px) {
    .blog-title {
      font-size: 14px; /* Further reduced font size for very small screens */
    }
    
    .blog-date {
      font-size: 10px; /* Further reduced font size for very small screens */
    }
    
    .blog-description {
      font-size: 12px; /* Further reduced font size for very small screens */
    }
  }
  