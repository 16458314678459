.recent-works {
  text-align: center;
  margin: 64px 0;
}

.recent-works h2 {
  font-size: 20px;
  margin-bottom: 4px;
  margin-top: 32px;
  letter-spacing: 0.2em;
}

.recent-works p {
  font-size: 14px;
  margin-bottom: 36px;
  margin-top: 2px;
}

.filter-buttons {
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-bottom: 36px;
}

.filter-buttons button {
  padding: 4px 10px;
  border: none;
  cursor: pointer;
  color: #333;
  border-radius: 20px;
  transition: background 0.3s ease;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  background: transparent; /* No color for default state */
}

.filter-buttons button img.filter-icon {
  width: 20px;
  height: auto;
}

/* UX & UI Option */
.filter-buttons button[data-filter='option1'] {
  background: transparent;
}

.filter-buttons button[data-filter='option1'].active {
  background: linear-gradient(45deg, #FDE1BC, #FFAEAE); /* Gradient for UX & UI */
  color: #D65B25; /* Text color for UX & UI on active state */
}

.filter-buttons button[data-filter='option1']:hover {
  background: linear-gradient(45deg, #FDE1BC, #FFAEAE); /* Same gradient for hover state */
  /* No change in text color on hover state */
}

/* Option 2 */
.filter-buttons button[data-filter='option2'] {
  background: transparent;
}

.filter-buttons button[data-filter='option2'].active {
  background: linear-gradient(45deg, #FBCEFF, #FFD1D1); /* Gradient for Option 2 */
  color: #9F2393; /* Text color for Option 2 on active state */
}

.filter-buttons button[data-filter='option2']:hover {
  background: linear-gradient(45deg, #FBCEFF, #FFD1D1); /* Same gradient for hover state */
  /* No change in text color on hover state */
}

/* Option 3 */
.filter-buttons button[data-filter='option3'] {
  background: transparent;
}

.filter-buttons button[data-filter='option3'].active {
  background: linear-gradient(45deg, #C3C2FF, #9DBEFF); /* Gradient for Option 3 */
  color: #455BBB; /* Text color for Option 3 on active state */
}

.filter-buttons button[data-filter='option3']:hover {
  background: linear-gradient(45deg, #C3C2FF, #9DBEFF); /* Same gradient for hover state */
  /* No change in text color on hover state */
}

.cards-container {
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr)); /* Responsive grid */
  gap: 20px;
  justify-items: center;
  width: 100%; /* Full width of the container */
  max-width: 720px; /* Maximum width */
  padding: 0 16px; /* Responsive padding */
}

.card {
  background: #fff;
  padding: 4px;
  border-radius: 10px;
  width: 100%;
  max-width: 336px;
  text-align: left;
  
}

.see-more-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 300%;
  margin-top: 10px;

}

.see-more-link {
  font-size: 16px;
  color: #3A8AFD;
  text-decoration: none;
  transition: color 0.3s ease;
}

.see-more-link:hover {
  color: #B353CE;
}

/* Media Queries for Responsiveness */

/* Extra Extra Large (≥1400px) */
@media (min-width: 1400px) {
  .cards-container {
    grid-template-columns: repeat(3, 1fr); /* Three cards per row */
  }
}

/* Extra Large (≥1200px) */
@media (min-width: 1200px) {
  .cards-container {
    grid-template-columns: repeat(3, 1fr); /* Three cards per row */
  }
}

/* Large (≥992px) */
@media (min-width: 992px) {
  .cards-container {
    grid-template-columns: repeat(2, 1fr); /* Two cards per row */
  }
}

/* Medium (≥768px) */
@media (min-width: 768px) {
  .filter-buttons {
    gap: 16px; /* Reduce gap between buttons */
  }

  .filter-buttons button {
    font-size: 14px; /* Smaller text size */
    padding: 4px 8px; /* Smaller padding */
  }

  .cards-container {
    grid-template-columns: repeat(2, 1fr); /* Two cards per row */
  }
}

/* Small (≥576px) */
@media (max-width: 576px) {
  .filter-buttons {
    gap: 12px; /* Reduce gap between buttons */
  }

  .filter-buttons button {
    font-size: 12px; /* Smaller text size */
    padding: 4px 6px; /* Smaller padding */
  }

  .cards-container {
    grid-template-columns: 1fr; /* One card per row */
    
  }
  .see-more-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 30%;
    margin-top: 20px;
  
  }
}

.sp {
  background: linear-gradient(45deg, #398CFD, #8854E5, #FF775C); /* Gradient colors */
  -webkit-background-clip: text; /* For WebKit browsers */
  background-clip: text; /* For other browsers */
  color: transparent; /* Hide the text color */
  font-weight: 400;
}

