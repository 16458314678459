/* src/components/GradientButton.css */
.gradient-button {
    background: linear-gradient(45deg, #8A30E3, #EE6BA1, #EAC893);
    border: none;
    border-radius: 50%; /* Make it round */
    width: 50px; /* Adjust size as needed */
    height: 50px; /* Adjust size as needed */
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 40px 0 #EE6BA1; /* Glowing shadow */
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .gradient-button:hover {
    transform: translateY(-10px); /* Move up slightly on hover */
  }
  .arrow-icon {
    width: 50%; /* Adjust size as needed */
    height: 50%; /* Adjust size as needed */
  }
  .gradient-button-link {
    text-decoration: none;
  }