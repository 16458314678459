.say-hi-section {
    width: 720px;
    height: auto;
    background: linear-gradient(45deg, #FFE9F4, #EAF3FF);
    padding: 64px;
    border-radius: 16px;
    margin-top: -12px;
  }
  
  .flex-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse; /* Image on the right */
  }
  
  .left-flex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 60%;
  }
  
  .text-section {
    margin-bottom: -2px;
  }
  
  .text-section p {
    margin-bottom: -24px;
    font-size: 16px;
    color: #333;
    text-align: left;
  }
  
  .button-section {
    display: flex;
    align-items: center;
    gap: 28px;
    margin-top: -24px !important;
    flex-wrap: wrap; /* Allow wrapping in responsive view */
  }
  
  .contact-button {
    background: linear-gradient(45deg, #DC79FF, #256BFA);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }
  
  .button-icon {
    width: 20px;
    height: 20px;
  }
  
  .icons-section {
    display: flex;
    gap: 24px;
    align-items: center;
  }
  
  .icons-section a img {
    width: 24px;
    height: 24px;
  }
  
  .right-flex {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35%;
  }
  
  .say-hi-image {
    max-width: 100%;
    border-radius: 8px;
  }
  
  @media (max-width: 768px) {
    .say-hi-section {
      width: 100%;
      padding: 32px;
    }
  
    .flex-container {
      flex-direction: column; /* Stack items vertically */
      align-items: center;
    }
  
    .left-flex {
      width: 100%;
      order: 2; /* Move text section below the image */
      margin-top: 24px;
    }
  
    .right-flex {
      width: 100%;
      order: 1; /* Move image section to the top */
    }
  
    .button-section {
        margin-top: 56px !important;
      flex-direction: row; /* Ensure buttons and icons are in one line */
      justify-content: space-between;
      gap: 16px;
    }
  
    .icons-section {
      margin-top: 0;
    }
  }
  