/* General Reset and Font Faces */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: "San Francisco", sans-serif;
}

@font-face {
  font-family: "San Francisco";
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfrancisco-light-webfont.woff");
  font-weight: 400;
}

@font-face {
  font-family: "San Francisco";
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfrancisco-bold-webfont.woff");
  font-weight: 800;
}

/* Navbar Container */
.navbar {
  position: fixed;
  top: 32px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 720px;
  height: 64px;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(10px); /* Adjust blur to match dock style */
  -webkit-backdrop-filter: blur(10px); /* For Safari */
  border-radius: 12px; /* Rounded corners similar to dock */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px 0 8px;
  transition: all 0.3s ease-in-out;
  z-index: 1000;
}

/* Navbar Logo */
.navbar img {
  border-radius: 8px;
  height: 52px;
  width: 52px;
}

/* Navbar Links */
.navbar-links {
  display: flex;
  gap: 32px;
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9); /* Slightly softer color */
}

.navbar-links a {
  text-decoration: none;
  color: inherit;
}

.navbar-links .my-story {
  background: linear-gradient(45deg, #398CFD, #8854E5, #FF775C); /* Gradient colors */
  -webkit-background-clip: text; /* For WebKit browsers */
  background-clip: text; /* For other browsers */
  color: transparent; /* Hide the text color */
}

/* Navbar Social Icons */
.navbar-social {
  display: flex;
  gap: 24px;
}

.navbar-social img {
  height: 24px;
  width: 24px;
}

/* Hamburger Menu */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.hamburger .bar {
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 4px 0;
  transition: all 0.3s ease-in-out;
}

.hamburger .bar.open {
  background-color: #fff;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .navbar {

    padding: 0 40px;
    
  }

  .navbar-links {
    display: none;
    position: absolute;
    top: 64px;
    left: 0;
    width: 100%;
    flex-direction: column;
    align-items: center;
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 0 0 12px 12px;
    padding-top: 30px; 
    padding-bottom: 30px; 
  }

  .navbar-links.open {
    display: flex;
  }

  .navbar-social {
    display: none;
    padding-bottom:5px;
  }

  .hamburger {
    display: flex;
  }

  .bar.open:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
    background-color: black;
  }

  .bar.open:nth-child(2) {
    opacity: 0;
  }

  .bar.open:nth-child(3) {
    transform: rotate(-45deg) translate(6px, -6px);
    background-color: black;
  }

 
  .dropdown-social {
    display: flex;
    justify-content: center;
    gap: 24px;
    margin-top: 20px;
  }

  .dropdown-social img {
    height: 24px;
    width: 24px;
  }
}

@media (max-width: 480px) {
  

  .navbar {
   
    width: 450px;
    padding: 0 40px;
    
  }
  .navbar-links {
    padding-top: 80px; 
  }

  .navbar-links a {
    font-size: 14px; /* Smaller font size on very small screens */
    margin-bottom: 10px; /* Increase spacing between links */
  }

  .navbar-social {
    display: flex;
    justify-content: center;
    gap: 16px;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .navbar-social img {
    height: 20px;
    width: 20px;
  }
}