/* src/components/Footer.css */

.footer {
    width: 100%;
    height: 200px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-top: auto; /* Push the footer to the bottom of the page */
    background: transparent;
}

.footer-image {
    width: 100%;
    height: auto;
    max-height: 250px; /* Adjust this value to control the image height */
    object-fit: cover;
 
}

.footer-content {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.quote-image {
    max-width: 500px; /* Adjust the size as needed */
    height: auto;
    opacity: 1; /* Ensure the quote image is fully opaque */
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
    .footer {
        height: 150px; /* Adjust height for mobile */
    }

    .footer-image {
        max-height: 150px; /* Adjust image height for mobile */
    }

    .quote-image {
        max-width: 300px; /* Adjust quote image size for mobile */
    }
}

@media (max-width: 480px) {
    .footer {
        height: 120px; /* Further adjust height for smaller screens */
    }

    .footer-image {
        max-height: 120px; /* Further adjust image height for smaller screens */
    }

    .quote-image {
        max-width: 200px; /* Further adjust quote image size for smaller screens */
    }
}
